export default {
  globalMediaCoverage: 'Global Media Coverage',
  verification: 'Verification',
  officeCode: 'Code (optional)',
  plsEnterOfficeCode: 'Please enter the office code',
  refundStatement: "Once the membership fee is paid, you will receive a 14-day trial period. During this 14-day period, you will be able to fully experience all the features and benefits of membership in SmoothTravel. If you decide within the 14-day trial period that you no longer wish to be a member, please contact us at <a style='color: red' href='mailto:membershipmanager{'@'}smoothtravel.com'>membershipmanager{'@'}smoothtravel.com</a>, and we will promptly refund your total membership fee. If an invoice is required for tax or accounting purposes, please feel free to contact <a style='color: red' href='mailto:membershipmanager{'@'}smoothtravel.com'>membershipmanager{'@'}smoothtravel.com</a> for assistance.",

  CPIC: 'China Pacific Insurance',
  travelTimes: 'Travel Times',
  chinaTravelTradeMedia: 'China Travel Trade Media',
  SHIAC: 'Shanghai International Arbitration Center',
  LIANLIAN: 'LianLian Global',
  comingSummer2024: 'Coming Summer 2024',
  pleaseCompleteTheBasicInformationOfTheCompany: "Both English and Chinese information is required for the basic company information, if you encounter translation difficulties, you can send an email to support{'@'}smoothtravel.com",
  congratulationsOnYourRegistration: 'One Final Step!',
  nowYouCanFindYourChinesePartnersHere: 'Complete your annual membership payment to access the services of SmoothTravel.',
  dmoRegisterCompleted: 'Thank you for your preliminary application which is currently under review in Shanghai. Once your application has been approved, you will receive an E-mail from SmoothTravel and you will then be able to populate your profile.',
  associationsFree: 'The annual membership fee for Government entities, Destination Management Organizations (DMOs), and Associations is waived.',
  getStarted: 'Get Started',
  startFreeMembershipTrial: 'Start your free trial membership',
  congratulationsRegistrationComplete: 'Congratulations, registration completed!',
  Welfare24: 'For overseas corporate members, a free six month trial membership is provided during the first half of 2024. Following a successful trial, you will be given the option to subscribe as a regular overseas corporate member with an annual membership fee of $2,000.',
  beforeRegistration: 'Why join SmoothTravel?',
  beforeRegistrationOne: "SmoothTravel is the official travel portal of the Shanghai Government (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>), linking China’s travel industry with travel entities around the world seeking to boost arrivals from China or their China travel business. The key benefits of SmoothTravel for overseas members are as follows:",

  beforeRegistrationItemOneTitle: 'Research top-tier outbound or inbound travel agents in China.',
  beforeRegistrationItemOneContent: 'At its core, SmoothTravel allows you to establish new working relationships with the leading outbound or inbound travel trade in China. Identify high quality travel partners in China which meet your specific needs, assess their capabilities, establish a dialogue, and enter into productive working relationships with select partners to boost your China business.',
  beforeRegistrationItemTwoTitle: 'Review the unique sales materials of selected members in English.',
  beforeRegistrationItemTwoContent: 'Having identified potential China travel trade partners, SmoothTravel gives you access to their sales materials in English to allow a comprehensive review of their offerings, capabilities, track record, and suitability as an additional China partner.',
  beforeRegistrationItemThreeTitle: 'Post Chinese translations of your sales materials.',
  beforeRegistrationItemThreeContent: 'Top tier Chinese travel trade are also then using SmoothTravel to identify new partners overseas including new destinations, cross-border tour operators, bus companies, hotel groups, attractions, retailers, restaurants, cruise lines etc. which are best suited to receive Chinese overseas travelers. To attract interest from Chinese members, posting powerful sales tools in Chinese on SmoothTravel is ideal. If necessary, SmoothTravel will provide translation support at cost using a team of translators specializing in developing sales materials for the China inbound and outbound travel market. Ensuring accurate and culturally relevant Chinese translations of your sales materials, catering to the needs and nuances of the China market will be a key to boosting your China business.',
  beforeRegistrationItemFourTitle: 'Establish new working partnerships to expand your China business.',
  beforeRegistrationItemFourContent: 'A SmoothTravel China Member Engagement Manager will lead you through the above process and work with you to ensure you are able to forge new working partnerships in China, and grow your China revenues rapidly but securely.',
  beforeRegistrationItemFiveTitle: 'Guarantee payments from Chinese partners and eliminate payment risk.',
  beforeRegistrationItemFiveContent: "To minimize payment risk in dealing with new Chinese partners, SmoothTravel has partnered with UnionPay (<span id='609135372051537921' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>) to provide members with a unique payment guarantee system which bridges the gap between the desire of Chinese travel trade to pay overseas suppliers at the conclusion of a tour, versus the desire of overseas members to receive pre-payment. UnionPay’s payment guarantee system effectively eliminates all payment risk in both China and overseas.",
  beforeRegistrationItemSixTitle: 'Secure neutral government bilingual dispute resolution services if ever needed.',
  beforeRegistrationItemSixContent: "In case of any disagreement between overseas and Chinese members, SmoothTravel has partnered with the Shanghai Government’s dispute resolution center (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>) to provide access to bilingual arbitrators specializing in resolving cross-border travel disputes, and in a unique position to provide a reliable arbitration mechanism at no cost to either party which navigates cultural and linguistic differences, and results in a fair and effective resolution of any conflict.",
  beforeRegistrationItemSevenTitle: 'Ensure Chinese visitors have comprehensive outbound travel insurance coverage.',
  beforeRegistrationItemSevenContent: "SmoothTravel has partnered with China Pacific Insurance (<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>) to develop China’s first ever comprehensive outbound travel insurance coverage specifically tailored for Chinese visitors, offering a safety net which protects the interests of Chinese travelers, Chinese outbound travel trade and overseas partners.",
  beforeRegistrationItemEightTitle: 'Provide and receive feedback regarding members.',
  beforeRegistrationItemEightContent: "SmoothTravel is the brainchild of the Shanghai Government’s Travel Quality Assurance Center (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>) who’s ultimate goal is to increase the quality of cross-border travel between China and the world and to allow top quality members overseas to partner with the best that China’s travel industry has to over. Facilitating reciprocal feedback mechanisms that drive continuous service improvement, ensuring the highest quality Chinese travel industry players partner with the right overseas members, and elevating the overall quality of overseas travel offerings in China, are all core goals of SmoothTravel.",
  beforeRegistrationItemNineTitle: 'Obtain the most up-to-date insights into China\'s outbound travel industry.',
  beforeRegistrationItemNineContent: "Staying abreast of the latest trends and insights into China's traveler mindset and the rapidly evolving dynamics of the world’s largest travel market is fundamental to success in the China market. SmoothTravel leverages unique access to government, media, and industry sources of information throughout China (<span id='563313891564601344' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see Key Stakeholders</span>) to provide overseas members with a comprehensive understanding of this ever-changing market and ensure the strategic grasp necessary to plot a long-term course to success targeting Chinese travelers.",
  beforeRegistrationItemTenTitle: 'Commission custom research to refine your China strategy and tactics.',
  beforeRegistrationItemTenContent: 'Where publicly available sources of information are not sufficient, custom research is available to more precisely formulate your strategy for the Chinese market and fine-tune tactics.',

  // 会员等级和权益详情
  membershipLevelsAndBenefitsDetails: {
    title: 'Detailed description of Corporate Members‘ Account Levels and Benefits',
    keyMemberBenefits: {
      title: 'Key Member Benefits',
      detail: `1. Research top-tier outbound or inbound travel agents in China<br />2. Review the unique sales materials of selected members in English<br />3. Obtain Chinese translations of your sales materials, as needed<br />4. Present your sales materials to new China partners<br />5. Establish new working partnerships to expand your China business<br />6. Guarantee payments from Chinese partners and eliminate payment risk<br />7. Secure neutral government bilingual dispute resolution services if ever needed<br />8. Ensure Chinese visitors have comprehensive outbound travel insurance coverage.<br />9. Provide and receive feedback regarding members<br />10. Obtain the most up-to-date insights into China's outbound travel industry<br />11. Commission custom research to refine your China strategy and tactics<br />12. Leverage the "SmoothTravel" logo to showcase membership status in China<br />`
    },
  },
  activateNewTopTierOutboundChinaTravelPartners: {
    title: 'Activate new top-tier outbound China travel partners',
    chineseMemberSearchFunction: {
      title: 'China Partner Search',
      detail: 'SmoothTravel provides overseas members with the ability to identify new inbound or outbound China travel trade partners, and expand your China business with ideally suited new partners. To identify the ideal Chinese travel trade partner which meets your requirements, please become a member of SmoothTravel by registering below.'
    },
    salesMaterialTranslationIntoChineseIfNeeded: {
      title: 'Sales material translation into Chinese if needed',
      detail: 'SmoothTravel’s member engagement manager will help you evaluate whether your sales materials are suitable for use in the China market to attract China trade and establish new working partnerships. If needed, SmoothTravel is able to provide translation support at cost using a team of translators specializing in developing sales materials for the China inbound and outbound travel markets. Ensuring accurate and culturally relevant Chinese translations of your sales materials and online content which cater to the needs and nuances of the China travel market will be a key to boosting your China business. To access SmoothTravel’s specialized tourism translation services, become a member of SmoothTravel by registering below.'
    },
    chineseMemberSalesMaterialEvaluation: {
      title: 'Chinese member sales material evaluation',
      detail: 'After selecting potential target travel trade partners, SmoothTravel provides overseas members with online access to comprehensive information about potential new Chinese partners. Through our user-friendly interface, overseas members can effortlessly discover a wealth of English materials posted by Chinese members. These materials in most cases include detailed company profiles, product catalogues, marketing presentations, business conditions etc. Additionally, overseas members can view client testimonials and case studies to be able to make an informed decision whether to initiate dialogue and establish a potential collaboration with any given Chinese partner. To access complete Chinese member profiles, register as a member of SmoothTravel below.'
    },
    supportFromSmoothTravelChinaMemberEngagementManager: {
      title: 'Support from SmoothTravel China Member Engagement Manager',
      detail: 'As a SmoothTravel member, you will be assigned a China Member Engagement Manager who will lead you through the above process and work with you to ensure you are able to forge new working partnerships in China and grow your China revenues rapidly but securely. To access this unique support in the China market, become a member of SmoothTravel by registering below.'
    }
  },
  minimizeYourChinaBusinessRisk: {
    title: 'Minimize your China business risk',
    paymentGuaranteeSystem: {
      title: 'Payment guarantee system',
      detail: "To minimize payment risk in dealing with new Chinese partners, SmoothTravel has partnered with UnionPay (<span id='609135372051537921' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>)to provide members with a unique payment guarantee system which bridges the gap between the desire of Chinese travel trade to pay overseas suppliers at the conclusion of a tour, versus the desire of overseas members to receive pre-payment. UnionPay’s payment guarantee system effectively eliminates all payment risk in both China as well as overseas. For access to the SmoothTravel/Union Pay payment guarantee system, become a member of SmoothTravel by registering below.",
    },
    bilingualDisputeArbitration: {
      title: 'Bilingual dispute arbitration',
      detail: "In case of any disagreement between overseas and Chinese members, SmoothTravel has partnered with the Shanghai Government’s specialized travel dispute resolution center(<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>)to provide access to bilingual arbitrators specializing in resolving cross-border travel disputes, and in a unique position to provide a reliable arbitration mechanism at no cost to either party which navigates cultural and linguistic differences, and results in a fair and effective resolution of any conflict. To access SmoothTravel’s dispute resolution platform, become a member of SmoothTravel by registering below.",
    },
    chinaOutboundTravelInsurance: {
      title: 'China outbound travel insurance',
      detail: "SmoothTravel has partnered with China Pacific Insurance “CPIC” (<span id='534690179236065280' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>click here for corporate profile</span>) to develop China’s first ever comprehensive outbound travel insurance coverage specifically tailored for Chinese visitors, offering a safety net which protects the interests of Chinese travelers, Chinese outbound travel trade and overseas partners. In the event of an accident, most costs will be covered, reducing the financial risk of Chinese and Overseas members. To learn more about SmoothTravel’s insurance program with CPIC and encourage your Chinese outbound travel trade partners to subscribe, simply register as a member of SmoothTravel below.",
    },
    memberEvaluationFeedbackLoop: {
      title: 'Member evaluation/feedback loop',
      detail: "SmoothTravel is the brainchild of the Shanghai Government’s Travel Quality Assurance Center (<span id='603523492931014656' class='seeKeyStakeholders' style='color: #0000ff; text-decoration: underline; cursor: pointer;'>see profile here</span>) who’s ultimate goal is to increase the quality of cross-border travel between China and the world, and to allow top quality overseas members to partner with the best that China’s travel industry has to offer. The core goals of SmoothTravel are to provide a reciprocal feedback mechanism that drives continuous service improvement in China and overseas, to ensure the highest quality Chinese travel industry players partner with the right overseas members, and to elevate the overall quality of overseas travel offerings in China.",
    },
    showcaseOfTopServiceQualityMembers: {
      title: 'Showcase of top service quality members',
      detail: 'SmoothTravel together with the Shanghai Government will publish an annual list of outstanding global players in the travel industry in various fields, recommended and selected by Chinese members for their exceptional quality. This showcase will evaluate service quality, professional capabilities, and customer satisfaction in the eyes of the China market. Similarly, top quality Chinese outbound and inbound travel agents will be showcased for overseas members based on the evaluations of overseas members.',
    },
  },
  nurtureYourChinaTravelIndustryRelationships: {
    title: 'Nurture your China travel industry relationships',
    chinaExpertTraining: {
      title: 'China Expert Training',
      detail: 'SmoothTravel offers a comprehensive two-way training platform. Overseas members can utilize this platform to train Chinese travel trade partners to become experts regarding their destination, services, or products. Likewise, SmoothTravel provides Chinese members with a platform to train overseas travel trade partners to become experts in catering to inbound Chinese tourists. In short, SmoothTravel\'s platform facilitates specialized training sessions tailored for both overseas and Chinese members. For more information on SmoothTravel’s training platform, please register as a member below.',
    },
    eventPromotionTargetingChinasOutboundTravelIndustry: {
      title: 'Event Promotion targeting China’s outbound travel industry',
      detail: 'Overseas members are able to organize promotional events and campaigns targeting Chinese Travel Trade, including training seminars, workshops, roadshows, trade shows, and trade product announcements via SmoothTravel. To promote your China events and campaigns to China’s outbound travel industry, register with SmoothTravel below.',
    },
    smoothTravelLogoSealOfApprovalOnChinaMarketingMaterials: {
      title: 'SmoothTravel Logo/seal of approval on China marketing materials',
      detail: 'SmoothTravel is the official travel portal of the Shanghai Government, linking East China’s travel industry with travel entities around the world.  Authorized overseas members are able to place the SmoothTravel logo on their China industry marketing materials as a stamp of approval from the Shanghai Government.',
    },
  },
  refineYourChinaStrategy: {
    title: 'Refine your China strategy',
    chinaOutboundConsumerAndTravelIndustryInsights: {
      title: 'China outbound consumer and travel industry insights',
      detail: 'SmoothTravel leverages extensive China government and industry resources to develop the latest China outbound travel market research, industry trends, and consumer tastes and preferences. SmoothTravel conducts surveys, in-depth interviews, focus group discussions, workshops, and on-site visits in order to produce updates on the state of China’s outbound travel markets and the perceptions of Chinese outbound travelers. The goal is to provide you with insights into the state of China’s outbound travel market, delivering data and analysis to help you leverage China market demand, changes, and dynamics. To access the latest Chinese consumer and outbound travel insights, become a member of SmoothTravel by registering below.',
    },
    customChinaMarketResearch: {
      title: 'Custom China market research',
      detail: 'Overseas members of SmoothTravel are able to commission custom China outbound travel market research, as needed, at cost.   Custom China market research can encompass brand awareness surveys, market share analysis, identification of key market players, product channel analysis and recommendations, competitor analysis, consumer segment identification, price analysis, strategic planning, and digital consultancy, among others. Our ultimate goal is to help you seize China outbound travel market opportunities, formulate targeted China marketing strategies, and assist you to identify the right partners in the China market. To access SmoothTravel’s custom research, register as a member below.',
    },
  },

  operatingOfficeAddress: 'Operating office address',
  pleaseEnterOperatingOfficeAddress: 'Please enter operating office address in Chinese',
  pleaseEnterOperatingOfficeAddressEn: 'Please enter operating office address',
  mainContactForTheChineseMarket: 'Main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarket: 'Please enter name of main contact for Chinese market in Chinese',
  pleaseEnterMainContactForTheChineseMarketEn: 'Please enter name of main contact for Chinese market',
  mainContactForTheChineseMarketPhone: 'The phone number of the main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarketPhone: 'Please enter the phone number of the main contact for the Chinese market',
  mainContactForTheChineseMarketEmail: 'The email address of the main contact for the Chinese market',
  pleaseEnterMainContactForTheChineseMarketEmail: 'Please enter the email address of the main contact for the Chinese market',
  pleaseEnterCorrectlyMainContactForTheChineseMarketEmail: 'Please enter correctly the email address of the main contact for the Chinese market',
  pleaseEnterContactInformation: 'Please enter contact information',
  officialWebsiteAddress: 'Official website address',
  pleaseEnterOfficialWebsiteAddress: 'Please enter official website address',
  termsAndConditions: 'Terms and conditions',
  pleaseEnterTermsAndConditions: 'Please enter terms and conditions',
  other: 'Other',
  pleaseUploadQRCode: 'Please upload QR code',
  releaseTime: 'Release time',
  eligibleToJoinTheQualityEnterprise: 'You have met the requirements and standards for joining the quality member, please wait patiently for the official review and notify the result',
  youAreNotYetACorporateMemberOnlyCorporateMembersCanParticipateInTheQualityMemberSelection: 'You are not yet a corporate member, only corporate members can participate in the quality member selection',
  creationTime: 'Creation time: ',
  orderStatus: 'Order status',
  approvalComments: 'Approval comments',
  paymentConfirmation: 'Payment confirmation is in progress. Please wait and do not refresh the page or navigate away.',
  paymentCompleted: 'Payment completed!',
  backToList: 'Back to List',

  /**
   * 以下是之前表里的数据
   */
  homepage: 'Homepage',
  corporate: 'Corporate',
  search: 'Search',
  services: 'Services',
  visitShanghai: 'Visit Shanghai',
  login: 'Login',
  signUp: 'Sign up',
  bannertoptitle: 'Shanghai Official Tourism Platform',
  joinUs: 'Join us',
  news: 'China Tourism Market & Consumer Insights',
  newsInformation: 'China Tourism Market & Consumer Insights',
  viewMore: 'View more',
  membershipPlans: 'Membership Plans',
  memberbottom: 'If you are already a member of SmoothTravel, please login. If you are a foreign entity interested in joining SmoothTravel, please check the membership benefits for details and join the official Shanghai Travel Platform to boost your business with China!',
  learnMore: 'Learn more',
  visitors: 'Visitors',
  individualMember: 'Individual Member',
  corporateMember: 'Smoothtravel Member',
  corporateVIP: 'Corporate VIP',
  publicInformation: 'Public information',
  training: 'Training',
  corporateInformation: 'Corporate information',
  corporateCollaboration: 'Corporate collaboration',
  platformPrivileges: 'Platform privileges',
  industryInformation: 'Industry information',
  bestselection: 'Annual selection of high quality corporate',
  rankingBoard: 'Ranking List',
  rankbottom: 'Shanghai Government\'s Cultural and Tourism Market Quality Monitoring and Service Center has selected a list of excellent service companies in the global tourism industry.',
  boardOfDirectors: 'Board of Directors',
  fAQ: 'FAQs',
  contactUs: 'Contact Us',
  contactusbottom: 'Feel free to reach out to the SmoothTravel team 24/7 (When contacting SmoothTravel HQ in Shanghai, members are kindly requested to include their Member ID in the subject line for authentication purposes)',
  homepageBannerIndustryNews: 'Homepage Banner/Industry News',
  keyMemberBenefitsTopServiceQualityMembers: 'Key Member Benefits/Showcase Top Service Quality members',
  memberEvents: 'Member Events',
  destinationTrainingSystem: 'Destination Training System',
  tourGuideAndTravelPlannerCertification: 'Tour Guide & Travel Planner Certification',
  memberSearchEngine: 'China Partner Search',
  memberContact: 'Chinese Member Contact',
  memberEngagementManagerSupport: 'Support of Member Engagement Manager',
  smoothTravelLogo: 'Permission to use SmoothTravel Logo',
  corporateMemberIntroductionEvaluationComplaintReport: 'Corporate Member Introduction/Evaluation/Complaint Report',
  paymentGuaranteeSystem: 'Payment Guarantee System',
  chinaTravelIndustryInsights: 'China Travel industry insights',
  chinaOutboundTravelInsurance: 'China Outbound Travel Insurance',
  smoothTravelEventsMemberEvents: 'SmoothTravel Events/Member Events',
  submittedForReviewSuccessfullyPleaseWaitForTheCompletionOfPlatformReview: 'Submitted for review successfully, please wait for the completion of platform review!',
  tplatformprivileges: 'TPlatform privileges',
  unionPayPayment: 'UnionPay Payment',
  baoxian: 'Insurance Purchase',
  InsurancePurchase: 'Insurance Purchase',
  viewall: 'view all',
  joinAsAnIndividualMember: 'Join as an individual member',
  joinAsACorporateMember: 'Join as a smoothtravel member',
  joinAsAssociationOrDestinationTourismBureauMember: 'Join as an Association/Destination Tourism Bureau member',
  limitedToIndividualAccountsOnly: 'Limited to individual accounts only',
  limitedToCorporateAccountsOnly: 'Limited to corporate accounts only',
  alreadyCorporateMember: 'Already a corporate member',
  alreadySmoothtravelMember: 'Already a smoothtravel member',
  alreadyCommitAuditThanks: 'Your membership application has been submitted to SmoothTravel HQ for review. We will process your request as soon as possible. Thank you for your patience.',
  trial: 'TRIAL',
  year: 'Year',
  pleasechoose: 'Please choose',
  personalcenter: 'Account',
  detailedinformationsupplement: 'Supplement info',
  top10Selection: 'Annual Top10 Selection is ongoing,',
  clickhere: 'click here',
  toknowmore: 'to know more!!',
  accountregistration: 'Individual Overseas Tour Guide Registration',
  associationDestinationTourismBureauRegistration: 'Association/Destination Tourism Bureau Registration',
  enteremail: 'Please enter email address',
  email: 'Email address',
  entertheverificationcode: 'Please enter the verification code',
  verificationcode: 'Verification code',
  enteryourpassword: 'Please enter your password',
  password: 'Password',
  enterarepeatpassword: 'Please repeat password',
  verifypassword: 'Verify password',
  passworderrorpleasereenter: 'Duplicate password error Please re-enter',
  wrongPassword: 'Wrong password!',
  pleaseenteryourname: 'Please enter your name in Chinese',
  pleaseenteryournameEn: 'Please enter your name',
  name: 'Name in Chinese',
  nameEn: 'Name',
  individualresume: 'Individual introduction in Chinese',
  individualresumeEn: 'Individual introduction',
  sendcaptcha: 'Send verification code',
  iagreeTermsofservice: 'Terms of Service and Privacy Policy',
  getcodenote:'Note: Click "Get Verification Code" to receive your verification code via email. Check your inbox and enter the code to complete your registration.',
  iagree: 'I agree',
  termsofservice: 'terms of service',
  and: 'and',
  close: 'Close',
  privacypolicy: 'Privacy policy',
  nextstep: 'Next step',
  completeregistration: 'complete registration',
  haveanaccountlogin: 'Have an account, log in',
  selectthetype: 'Please select the type of account you want to register',
  personalaccount: 'Individual Overseas Tour Guide',
  Suitableforpersonal: 'Suitable for personal use, such as tour guide, tour leader, etc',
  corporateaccount: 'Overseas Corporate Member',
  suitableforcompanyuse: 'For overseas companies seeking to attract more business from the East China market',
  note: 'Note: Account type selection cannot be changed after successful registration',
  haveanaccount: 'Have an account, log in',
  enterprise: 'Overseas Corporate Member Registration',
  notesaved: 'Note: Please ensure you select the most appropriate industry category as it cannot be changed by users once saved. For any changes needed thereafter, please contact our support team for assistance.',
  identityWarning:
    'For example, if you are a Travel Agent offering services such as ground transportation, accommodation reservation, and more, please select “Travel Agent” and provide detailed information about all your business activities in the “Company Activity” or “Company Description” section below.',
  businessidentity: 'Industry',
  otherBusinesses: 'Company Activity -- select as many as apply',
  psBusinessidentity: 'Please select industry',
  qybriefintroduction: 'Brief Description of your Organization',
  pleaseProvideABriefPersonalIntroduction: 'Please provide a brief personal introduction in Chinese.',
  pleaseProvideABriefPersonalIntroductionEn: 'Please provide a brief personal introduction.',
  peqybriefintroduction: 'Please enter brief description of your organization',
  shouyeemail: 'Email address',
  pleaseenteryouremailnumber: 'Please enter your email address',
  shouyepass: 'Password',
  shouyepleaseenteryourpassword: 'Please enter your password',
  noaccountyet: 'No account yet? Go to register',
  shouyeforgetthepassword: 'Forget the password？',
  modifyemail: 'Modify email address',
  pleaseenterourwebsiteloginpassword: 'Please enter your website login password',
  pleaseentertheverificationcode: 'Please enter the verification code',
  shouyeverificationcode: 'Verification code',
  sendverificationcode: 'Send verification code',
  synextstep: 'Next step',
  accomplish: 'Accomplish',
  verificationphone: 'Verification phone',
  pleaseenteryourphonenumber: 'Please enter your phone number',
  acknowledgementoforder: 'Annual Membership payment',
  orderservices: 'Order services',
  seniorcorporatemember: 'Senior corporate member',
  servicehours: 'Service hours',
  orderingenterprise: 'Ordering enterprise',
  orderprice: 'Annual membership fee',
  modofpayment: 'Mode of payment',
  eChatPay: 'WeChat Pay',
  paybyAlipay: 'Alipay',
  banktransfer: 'Bank transfer',
  payment: 'payment',
  goToPay: 'go to pay',
  wechat: 'WeChat',
  alipay: 'Alipay',
  turnonthecellphone: 'Turn on the cell phone',
  sweep: 'Sweep',
  shouyebanktransfer: 'Bank transfer',
  fterpaymentiscompleted: 'After payment is completed, please upload payment picture receipt or screenshot',
  fullnameofenterprise: 'Full name of enterprise',
  paymentQrcode: 'Payment qrcode',
  deqi: 'Deqi (Shanghai) Management Consulting Co., LTD',
  bankaccount: 'Bank account',
  depositbank: 'Deposit bank',
  transfernote: 'Transfer note',
  bankAddress: 'Bank address',
  swiftCode: 'Swift Code',
  pleasenote123: '（Please note this information when making payment）',
  paymentAmount: 'Payment amount',
  paymentreceipt: 'Payment receipt',
  pleaseuploadapicture: 'Please upload a picture or screenshot of the payment receipt. jpg、jpeg or png is supported',
  submit: 'Submit',
  confirmPayment: 'Confirm payment',
  good: 'Good',
  corporatemembershiptrialapplication: 'Corporate membership trial application',
  inordertobetter: 'In order to better give different types of enterprises/enterprise members trial is a limited period of welfare policy for enterprises to join the platform, we will give a certain period of enterprise membership trial qualification according to the completeness of the information you fill in as a consideration.',
  filloutthepaperworkfirst: 'Fill out the paperwork first',
  applynow: 'Apply now',
  joinmembersuccessfully: 'Join member successfully',
  applicationssuccessful: 'Application is successful, please wait for official review',
  order: 'My order',
  hset: 'Settings',
  hlanguage: 'language',
  plsSelectLanguage: 'Please select a language',
  hlogout: 'Log out',
  languageswitch: 'Language switch',
  logoutornot: 'Are you sure to exit?',
  hquit: 'Yes',
  councilis: 'The Council is the executive body of the Members\' Congress and is responsible to the members\' Congress',
  alllists: 'All lists',
  confirmexit: 'Confirm exit',
  registeredsuccessfully: 'Registered successfully',
  personalidentity: 'Personal identity',
  associationDestinationTourismBureau: 'Association/Destination Tourism Bureau',
  identity: 'Identity',
  psIdentity: 'Please select identity.',
  broadheading: 'broad heading',
  industry: 'industry',
  cancel: 'Cancel',
  hsave: 'Save',
  completeheregistration: 'Complete the registration and go to fill out more information',
  completeRegistrationAndSubmitForReview: 'Complete registration and submit for review',
  pleaseenteranewemailaddress: 'Please enter a new email address',
  pleaseentername: 'Please enter Name in Chinese',
  pleaseenternameEn: 'Please enter Name',
  name1: 'Name',
  resetpasswords: 'Reset passwords',
  backtologin: 'Back to login',
  qualificationCertificate: 'Qualification Certificate',
  roomjingannew: 'Jing An New Century Plaza #903, <br />No. 188 Wu Jiang Road Shanghai, 200041, China',
  runby: 'Operated by:',
  media: 'In Partnership with:',
  termof: 'Terms of service',
  corporationname: 'Corporation name (Chinese)',
  corporationnameEn: 'Corporation name',
  enteraname: 'Please enter enterprise name (Chinese)',
  enteranameEn: 'Please enter enterprise name',
  associationName: 'Association name (Chinese)',
  associationNameEn: 'Association name',
  pleaseUploadProfilePicture: 'Please upload logo as avatar',
  pleaseEnterAssociationName: 'Please enter the name (Chinese)',
  pleaseEnterAssociationNameEn: 'Please enter the name (English)',
  footerAddress: 'Jing An New Century Plaza #903, \nNo. 188 Wu Jiang Road Shanghai, 200041, China',
  stayloggedin: 'Keep logged in',
  finish: 'Complete',
  paidPleaseWaitForOfficialReview: 'Payment submitted, please wait for official review.',
  submittedPleaseWaitForOfficialReview: 'Submitted, Please wait for official review',
  onlyenterpris: 'Only enterprise accounts can join',
  personalaccountonly: 'Personal account only',
  directtesting: 'Direct testing',
  youdonothavepermission: 'Please join the membership.',
  approvalIsRequiredForAccess: 'Thank you for your preliminary application which is currently under review in Shanghai. Once your application has been approved in 1-2 business days, you will receive an E-mail from SmoothTravel giving you full access to all services and allowing you to populate your profile.',
  pleaseLoginFirst: 'Please register as a member to access the many services of SmoothTravel.',
  individualUsersDoNotHaveThatPermission: 'Individual users do not have that permission.',
  ok: 'OK',
  getcode: 'Get Verification Code',
  subordinateRegion: 'Region',
  psSubordinateRegion: 'Please select Region',
  paymentTips0: 'Note: Payments using WeChat Pay, AliPay, and Bank Transfer will be processed in Chinese RMB.',
  paymentTips1: '',
  paymentTips2: 'Note: AsiaPay payments using your Visa or Mastercard will be billed in Hong Kong dollars and converted to your local currency using a real-time exchange rate used by your card issuing bank or AsiaPay.',
  aliPayTips: 'Open Alipay on your phone and use the \'Scan\' feature.',
  wechatPayTips: 'Open WeChat on your phone and use the \'Scan QR Code\' feature.',
  ps:'Note：',

  // 排行榜挪过来的
  outTop10ListsOfTheRecommendedCompanies7toGlobalTourismIndustry: 'The ranking list is a globally recognized list of outstanding service enterprises in the travel industry, recommended and selected by peers for their exceptional quality.',
  numberOfRankings: 'Number of rankings: ',
  launchTime: 'Launch time: ',
  blacklist: 'Blacklist',
  ranklistone: 'This list assesses and evaluates various aspects such as service quality, professional capabilities, and customer satisfaction in the tourism sector. Different types of lists include those that focus on performance or specific indicators in the areas of service quality, professional expertise, and customer satisfaction.',
  ranklisttwo: '1.Destination ranking list: selects the best tourist destinations, usually based on factors such as the number of attractions, cultural value, tourism facilities, and environmental quality of the destination.',
  ranklistthree: '2.Accommodation ranking list: selects the best accommodation or accommodation brands based on factors such as customer evaluation, service quality, facility conditions, geographic location, and price.',
  ranklistfour: '3.Airline ranking list: selects the best airlines, usually considering indicators such as flight punctuality, customer satisfaction, service quality, and onboard facilities.',
  ranklistfive: '4.Tourist attraction ranking list: selects the best tourist attractions, considering aspects such as beauty, historical value, tourist experience, and service quality.',
  ranklistsix: '5.Tourism activity ranking list: selects the best tourism activities or festivals, based on factors such as uniqueness, influence, participation rate, and tourist feedback.',
  ranklistseven: '6.Best travel agency ranking list: selects outstanding travel agencies in the tourism industry, based on factors such as customer evaluation, service quality, product diversity, and innovation.',
  ranklisteight: '7.High-end travel agency ranking list: selects travel agencies focusing on the high-end tourism market and providing luxury travel experiences, considering factors such as itinerary customization capabilities, service level, and cooperation with high-end hotels.7',
  ranklistnine: '8.Travel agency network ranking list: selects the largest or most influential travel agency network or chain organization, based on indicators such as the number of branch offices, global coverage, and market share.',
  ranklisttwenty: '10.Consumer evaluation ranking list: selects popular travel agencies based on real consumer evaluations and feedback, with a focus on customer satisfaction, service attitude, itinerary arrangements, and after-sales support.',
  ranklisttwentyone: '11.Best travel agency ranking list by country or region: selects outstanding travel agencies within specific countries or regions, usually emphasizing their understanding of local tourism resources, professional knowledge, and service quality.',
  ranklisttwentytwo: 'These ranking lists can provide references and guidance for the industry to make better travel decisions. However, each list has its selection criteria and preferences. The platform will comprehensively consider information from multiple sources to obtain more comprehensive and objective views.',
  ranklist: 'Ranking List ',
  listintroduction: 'List introduction',

  // 理事会挪过来的
  councilone: 'The Council is the permanent authority of SmoothTravel, elected by the SmoothTravel General Assembly and responsible to SmoothTravel. It is composed of several members, including the president and vice presidents, and several other members. Members have been employed in the travel sector for more than 10 years, possess good professional ethics and standards, and have strong decision-making skills and a dedicated spirit. They are elected from member representatives who are enthusiastic about the public welfare activities of the industry. The term of office of each director is the same as the term of office of the representative of the current member. Candidates for the Board of Directors shall be elected according to the corresponding number of seats.',
  'thePlatformEstablishesAnExecutiveCouncil,WhichIsElectedFromAmongTheDirectors.DuringTheRecessOfTheBoardOfDirectors,TheExecutiveCouncilExercisesThePowersOfTheBoardAndIsAccountableToTheBoard': 'The platform establishes an Executive Council, which is elected from among the directors. During the recess of the Board of Directors, the Executive Council exercises the powers of the Board and is accountable to the Board',
  boardMembers: 'Board Members',
  boardComposition: 'Board Composition',
  boardCompositionOne: '1. Chairman (1): Responsible for overall leadership and decision-making, presides over board meetings, ensuring the realization of the platform\'s strategic objectives.',
  boardCompositionTwo: '2. Vice Chairman (1): Assists the Chairman, assumes responsibilities in the Chairman\'s absence, and leads specific projects.',
  boardCompositionThree: '3. Secretary Group (3)',
  boardCompositionThreeOne: '- Secretary-General (1): Manages the Secretariat\'s work, assists the Chairman and Vice Chairman, and is responsible for recording board documents and resolutions.',
  boardCompositionThreeTwo: '- Secretaries (2): Assist the Secretary-General, handling document management, meeting preparation, and related affairs.',
  boardCompositionFour: '4. Directors (5): Participate in decision-making, provide strategic advice, and represent the platform in specific domains.',
  boardCompositionFive: '5. Executive Directors (3): Engage in assisting the execution and management of the platform\'s daily operations.',
  membershipRequirements: 'Membership Requirements',
  counciltwo: '(1) Attain qualification for membership on this platform.',
  councilthree: '(2) Demonstrate a significant level of influence in the tourism industry.',
  membershipRequirementsThree: '(3) Possess international perspectives and collaboration experience.',
  membershipRequirementsFour: '(4) Actively engage in platform activities and initiatives.',
  electionAndRemovalOfDirectors: 'Election and Removal of Directors',
  councilfour: '(1) Inaugural Board:',
  councilfourOne: '• The founders should clearly nominate the inaugural board at the time of platform establishment, ensuring representation of various interests and professional domains.',
  councilfive: '(2) Authorized by the General Assembly of Members:',
  councilfiveOne: '• The election and removal of directors are executed with the authorization of the General Assembly of Members to ensure the legality and transparency of decisions.',
  councilfiveTwo: '• The General Assembly of Members may elect directors through voting or other legal means.',
  councilfiveThree: '(3) Election Procedure:',
  councilfiveFour: '• Nomination Stage: Members can nominate candidates with significant influence and professional experience.',
  councilfiveFive: '• Election Stage: The General Assembly of Members elects directors through voting, ensuring broad representation.',
  councilfiveSix: '(4) Removal Procedure:',
  councilfiveSeven: '• Removal Proposal: Members of the General Assembly or members of the board can propose removal.',
  councilfiveEight: '• Board Review: The board reviews the removal proposal to ensure fairness in the process.',
  councilfiveNine: '• General Assembly Vote: The General Assembly of Members votes to decide whether to remove a director.',
  councilfiveTen: '(5) Supplementary Procedure:',
  councilfiveEleven: '• The board can supplement directors during their term, but the number of additions should not exceed 1/5 of the original total.',
  councilfiveTwelve: '• The supplementary procedure requires board approval and reporting at the next General Assembly of Members.',
  councilfiveThirteen: '(6) Unit Representative Adjustments:',
  councilfiveFourteen: '• If a director\'s unit needs to adjust its representative, written notification should be provided to the platform and reported to the board or executive board.',
  councilfiveFifteen: '• If the unit adjusting its representative also serves as an executive director, the representative should be adjusted simultaneously.',
  // councilsix: '每个理事单位只能选派一名代表担任理事。单位调整理事代表，由其书面通知本平台，报理事会或者常务理事会备案。该单位同时为常务理事的，其代表一并调整。',
  rightsOfDirectors: 'Rights of Directors',
  councilseven: '(1) Election, Eligibility, and Voting Rights of the Board of Directors:',
  councilsevenOne: '• Election Rights: The right to participate in the election process of board members, ensuring reasonable representation.',
  councilsevenTwo: '• Eligibility: The qualification to be a candidate for the board of directors, contributing to the platform.',
  councilsevenThree: '• Voting Rights: Equal voting rights at board meetings, participating in decision-making and platform affairs discussions.',
  councileight: '(2) Information, Recommendation, and Supervision Rights regarding the Platform\'s Work, Financial Situation, and Major Issues:',
  councileightOne: '• Information Rights: The right to be informed about the progress of the platform\'s work, financial status, and major decisions, ensuring transparency.',
  councileightTwo: '• Recommendation Rights: The right to propose suggestions and opinions on platform work, promoting development and improvement.',
  councileightThree: '• Supervision Rights: The right to supervise platform work, ensuring adherence to established goals and standards.',
  councilnine: '(3) Participation in the Formulation of Industry Quality Service Standards, and Proposal Rights:',
  councilnineOne: '• Formulation Rights: The right to participate in the formulation of industry quality service standards, promoting the overall improvement of the industry.',
  councilnineTwo: '• Proposal Rights: The right to propose opinions and suggestions on industry standards, ensuring their rationality and practicality.',
  councilten: '(4) Right to Propose the Convening of Special Meetings to the President or Board of Directors:',
  counciltenOne: '• Proposal Right: The right to propose the convening of special meetings to the president or board of directors to address urgent matters.',
  counciltenTwo: '• Participation Right: The right to participate in discussions and decision-making at special meetings, ensuring timely and effective problem resolution.',
  rightsOfDirectorsFive: '(5) Participation in Important Platform Activities and Promotion:',
  rightsOfDirectorsFiveOne: '• Participation Right: The right to participate in significant platform events, showcasing the image and fostering cooperation.',
  rightsOfDirectorsFiveTwo: '• Promotion Right: The right to participate in platform promotional activities, enhancing the platform\'s image and recognition within the industry and the public.',
  rightsOfDirectorsSix: '(6) Enjoyment of Intellectual Property and Protection Rights of Trade Secrets:',
  rightsOfDirectorsSixOne: '• Intellectual Property Rights: The right to enjoy protection of intellectual property contributed to the platform\'s development.',
  rightsOfDirectorsSixTwo: '• Trade Secrets: The right to protect and enjoy the platform\'s trade secrets, ensuring competitiveness and uniqueness.',
  dutiesOfDirectors: 'Duties of Directors',
  councileleven: 'Directors shall comply with laws, regulations, and the provisions of this charter, faithfully fulfill their duties, and safeguard the interests of the platform. They shall fulfill the following obligations:',
  counciltwelve: '(1) Attend Board Meetings and Implement Board Resolutions:',
  counciltwelveOne: '• Attendance Duty: Directors should regularly attend board meetings to ensure a timely understanding of the platform\'s development and decision-making.',
  counciltwelveTwo: '• Resolution Implementation: Actively implement resolutions passed by the board to ensure consistency and stability in the platform\'s direction.',
  councilthirteen: '(2) Exercise Rights within the Scope of Responsibilities, Without Overstepping:',
  councilthirteenOne: '• Consistency of Rights and Responsibilities: Exercise rights within the scope of one\'s responsibilities, ensuring the legality and effectiveness of decisions.',
  councilthirteenTwo: '• Avoid Overstepping: Directors should avoid overstepping in the exercise of rights, maintaining the rationality and transparency of decision-making.',
  councilfourteen: '(3) Avoid Exploiting Directorial Powers for Unfair Personal Gain:',
  councilfourteenOne: '• Integrity: Directors must not exploit their positions for personal gain, upholding the platform\'s fairness and integrity.',
  councilfifteen: '(4) Refrain from Engaging in Activities Harming the Legitimate Interests of the Platform:',
  councilfifteenOne: '• Safeguard Interests: Directors should actively safeguard the platform\'s legal rights and refrain from activities that harm the platform\'s interests.',
  councilsixteen: '(5) Do Not Disclose Confidential Information Involving the Platform Obtained During the Term, Except as Required by Law or Regulations:',
  councilsixteenOne: '• Confidentiality Duty: Maintain the confidentiality of the platform\'s trade secrets and sensitive information to ensure the security and stability of platform information.',
  councilseventeen: '(6) Exercise Legally Granted Authority Prudently, Seriously, Diligently, and Independently:',
  councilseventeenOne: '• Prudence and Diligence: Exercise responsibilities with caution and diligence, maintaining a diligent attitude to ensure the accuracy and feasibility of decisions.',
  councilseventeenTwo: '• Independent Exercise of Rights: When exercising legally granted authority, maintain independence and resist undue influence.',
  councileighteen: '(7) Accept Legal Supervision and Reasonable Suggestions from Supervisors in Performing Duties:',
  councileighteenOne: '• Accept Supervision: Directors should actively accept legal supervision from supervisors in the performance of their duties, cooperating with the work of supervisors.',
  councileighteenTwo: '• Consider Reasonable Suggestions: Director should consider and actively improve based on reasonable suggestions from supervisors.',
  dutiesOfDirectorsEight: '(8) Actively Participate in Platform Development Planning and Strategy Formulation:',
  dutiesOfDirectorsEightOne: '• Participation in Planning: Directors should actively participate in the platform\'s long-term development planning and strategy formulation, providing constructive suggestions and opinions for the platform\'s future direction.',
  dutiesOfDirectorsEightTwo: '• Strategic Execution: Collaborate with other directors to ensure that the formulated development plans and strategies are comprehensive and advantageous.',
  dutiesOfDirectorsNine: '(9) Represent the Platform in Participating in Industry and Social Affairs:',
  dutiesOfDirectorsNineOne: '• Industry Representation: As representatives of the platform, directors should actively participate in relevant industry activities and affairs, enhancing the platform\'s influence in the industry.',
  dutiesOfDirectorsNineTwo: '• Social Engagement: Actively participate in social welfare activities, contributing to the improvement of the image of cultural and tourism quality services and establishing good relations with society.',
  powersOfTheBoardOfDirectors: 'Powers of the Board of Directors:',
  councilnineteen: '(1) Implement the resolutions of the Members\' Congress.',
  counciltwenty: '(2) Elect and remove executive directors and responsible persons.',
  counciltwentyone: '(3) Decide on other major matters.',
  powersOfTheBoardOfDirectorsFour: '(4) Formulate and approve the internal management system of the platform.',
  powersOfTheBoardOfDirectorsFive: '(5) Examine and approve cooperation agreements and important contracts.',
  otherProvisions: 'Other provisions:',
  counciltwentytwo: '(1) Transition and Term:',
  counciltwentytwoOne: '• The term of the Board of Directors aligns with that of the General Assembly of Members, and elections and transitions occur simultaneously.',
  counciltwentythree: '(2) Conditions for Board Meetings:',
  counciltwentythreeOne: '• Board meetings require the attendance of over 2/3 of directors to convene, and resolutions must be approved by over 2/3 of attending directors to take effect.',
  counciltwentyfour: '(3) Disqualification Conditions:',
  counciltwentyfourOne: '• Directors automatically lose their directorial qualifications if they fail to attend board meetings twice.',
  counciltwentyfive: '(4) Election Method for Executive Directors:',
  counciltwentyfiveOne: '• Executive directors are elected by the board of directors through anonymous voting.',
  counciltwentysix: '(5) Election Method for Leaders:',
  counciltwentysixOne: '• Leaders are elected by the board of directors through anonymous voting from among the executive directors.',
  counciltwentyseven: '(6) Removal Procedure:',
  counciltwentysevenOne: '• The removal of executive directors and leaders requires a vote of over 2/3 of attending directors.',
  counciltwentyeight: '(7) Election Procedure:',
  counciltwentyeightOne: '• In the election of executive directors and leaders, the candidates with the highest vote counts are elected, provided that the votes received are not less than 2/3 of the total votes.',
  // counciltwentynine: '但当选的得票数不得低于总票数的2/3。',
  councilthirty: '(8) Meeting Frequency:',
  councilthirtyOne: '• The Board of Directors must convene at least once a year, and in exceptional circumstances, online meetings may be conducted.',
  councilthirtyone: '(9) Special Meetings:',
  councilthirtyoneOne: '• Special board meetings must be convened if proposed by the president or 1/5 of the directors.',
  otherProvisionsTen: '(10) Chairing Special Meetings:',
  otherProvisionsTenOne: '• The president cannot chair special board meetings; a leader from the platform, elected by the proposer, presides over the meeting.',
  executiveCouncil: 'Executive Council:',
  councilthirtythree: 'The platform establishes an executive board as a crucial component of the board of directors, elected by the board of directors, consisting of 3 members. The executive board exercises key powers of the board of directors and is responsible for the board of directors during its recess.',
  councilthirtyfour: '(1) Scope of Authority:',
  councilthirtyfourOne: '• The executive board exercises various powers of the board of directors, including but not limited to emergency decision-making, daily management, and financial approvals.',
  councilthirtyfive: '(2) Term and Transition:',
  councilthirtyfiveOne: '• The executive board\'s term aligns with that of the board of directors, with elections and transitions occurring simultaneously.',
  councilthirtysix: '(3) Conditions for Meeting Convening:',
  councilthirtysixOne: '• Executive board meetings require the attendance of over 2/3 of executive directors to convene, and resolutions must be approved by over 2/3 of attending executive directors to take effect.',
  councilthirtyseven: '(4) Disqualification Conditions:',
  councilthirtysevenOne: '• Executive directors automatically lose their executive director qualifications if they fail to attend executive board meetings twice.',
  councilthirtyeight: '(5) Meeting Frequency:',
  councilthirtyeightOne: '• The executive board must convene at least once every 6 months and in exceptional circumstances, meetings may be conducted through other communication means.',
  councilthirtynine: '(6) Special Meetings:',
  councilthirtynineOne: '• Special executive board meetings must be convened if proposed by the president or 1/3 or more of the executive directors.',
  executiveCouncilSeven: '(7) Chairing Special Meetings:',
  executiveCouncilSevenOne: '• The president cannot chair special executive board meetings; a leader from the platform, elected by the proposer, presides over the meeting.',
  council: 'Council',
  councilthirtytwo: 'The President cannot preside over the meeting of the Provisional Council, and the proposer shall elect a person in charge of the platform to preside over the meeting.',

  // source orderFormSettings 挪过来的
  enterpriseMembershipRecharge: 'Enterprise membership recharge',
  loginEmail: 'Login email address',
  modify: 'Modify',
  loginMobileNumber: 'Login mobile number',
  loginPassword: 'Login password',
  pleaseEnterWebsiteLoginPassword: 'Please enter website login password',
  pleaseEnterNewEmail: 'Please enter new email address',
  'successMessage,E.g.,RegisteredSuccessfully/InformationModifiedSuccessfully/PaymentSuccessful': 'Success message, e.g., Registered successfully/Information modified successfully/Payment successful',
  'warningMessage,E.g.,InsufficientPermissions': 'Warning message, e.g., Insufficient permissions',
  myorder: 'My order',
  corporatememberpurchase: 'General corporate member purchase',
  paymentamount: 'Payment amount: ',
  ordernumber: 'Order number: ',
  expirationTime: 'Expiration time：',
  modeofpayment: 'Mode of payment: ',
  wechatpay: 'WeChat Pay',
  set: 'Set',
  notadded: 'Not added',
  add: 'Add',
  haveset: 'Have set',

  // source usercenter 挪过来的
  myOrders: 'My orders',
  settings: 'Settings',
  language: 'Language',
  logout: 'Logout',
  editProfile: 'Edit profile',
  leaveEnterprise: 'Leave',
  personalMembership: 'Personal membership',
  contactInformation: 'Contact information',
  myExpertCertificates: 'My expert certificates',
  myTrainingRecords: 'My training records',
  expirationime: 'Expiration time: ',
  ontinuetheexamination: 'Continue the examination',
  alreadypassed: 'Already passed',
  qualificationcertificate: 'Qualification certificate',
  permanentvalidity: 'Permanent validity',
  mycredentials: 'My credentials',
  sucasID: 'Such as ID card/passport, tour leader pass/tour guide pass, vocational training certificate, destination and training certificate or information, honors obtained, etc. jpg, png and pdf files are supported.',
  gotoCertificate: 'No certificate, go to add a certificate',
  notYet: 'Not yet obtained',
  goaddinformation: 'I don\'t have any information yet. Go and add it now',
  website: 'Personal website',
  telephone: 'Telephone',
  /**
   * 表里数据结束
   */
}
